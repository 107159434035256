// pusherAuthorizer.js
import { getSentry, Severity } from '../resource/sentry.js';
import { pusher as pusherDebug } from '../resource/debug.js';
import { getSocket } from './pusher.js';
import { addSecretKey } from './pusherKeychain.js';
import { getIsCriticalChannel } from '../resource/pusherUtils.js';
import { REFRESH_ACCESS_TOKEN } from './SWMessageTypes.js';
import { sendMessageToApp } from './pusherController.js';
import { bufferedAuthorizer } from './universalPusher.js';

const pusherDebugLog = pusherDebug.extend('log:pusherAuthorizer');
const pusherDebugError = pusherDebug.extend('error:pusherAuthorizer');

const pusherLog = async ({ level = Severity.Info, messages, data }) => {
  const logger = level === Severity.Error ? pusherDebugError : pusherDebugLog;
  if (data) {
    logger(...messages, data);
  } else {
    logger(...messages);
  }
  const { addBreadcrumb } = await getSentry();
  if (addBreadcrumb) {
    return addBreadcrumb({
      category: 'pusher',
      level,
      message: messages.join(' '),
      data,
    });
  }
};

let shouldRetryChannels = [];
export const getIsRetryChannel = ({ channelName }) =>
  shouldRetryChannels.includes(channelName);

export const addRetryChannel = ({ channelName }) => {
  if (!shouldRetryChannels.includes(channelName))
    shouldRetryChannels.push(channelName);
};

export const removeRetryChannel = ({ channelName }) => {
  const index = shouldRetryChannels.indexOf(channelName);
  if (index > -1) shouldRetryChannels.splice(index, 1);
};

let authorizers = {};
let authorizerLanguage;
export const clearAuthorizers = () => {
  authorizers = {};
};

/**
 * Set language code for pusher batch authenticate.
 * @param {string} {language} - language code
 */
export const setLanguage = ({ language } = {}) => {
  authorizerLanguage = language;
};

export const authorizer = ({
  channel,
  options,
  authHeaders,
  authBatchMaxCount,
  onAuth,
}) => {
  return {
    authorize(socketId, callback) {
      let isAuthFetched = false;
      const injectedCallback = (error, auth) => {
        if (!error) {
          isAuthFetched = true;
          onAuth({ channel: channel.name, data: auth });
          callback(error, auth);
          pusherLog({
            messages: [`${channel.name} authenticated with buffer`],
            data: {
              auth,
              channelName: channel.name,
              channelData: JSON.parse(auth.channel_data || '{}'),
            },
          });
        }
      };
      bufferedAuthorizer({
        channelName: channel.name,
        socketId,
        options,
        callback: injectedCallback,
      });
      if (isAuthFetched) {
        return;
      }

      const key = `${socketId}:${options.authEndpoint}`;
      let authorizer = authorizers[key];
      if (!authorizer || !(authorizer instanceof BufferedAuthorizer)) {
        authorizer = authorizers[key] = new BufferedAuthorizer({
          socketId,
          authEndpoint: options.authEndpoint,
          authDelay: options.authDelay,
          authHeaders,
          authBatchMaxCount,
          onAuth,
        });
      }
      authorizer.add(channel.name, callback);
    },
  };
};

// Re-try duration will increase gradually.
const backoff = ({ backoffUnitMsec = 1000 }) => {
  let backoffCount = 0;
  let lastExecuteTimestamp = Date.now();
  let backoffTimeout = null;

  return ({ backoffCallback }) => {
    clearTimeout(backoffTimeout);
    const offsetMsec = backoffCount * backoffCount * backoffUnitMsec;
    const nextTimestamp = lastExecuteTimestamp + offsetMsec;
    backoffTimeout = setTimeout(() => {
      backoffCount = backoffCount + 1;
      lastExecuteTimestamp = Date.now();
      return backoffCallback();
    }, nextTimestamp - Date.now());
    return backoffTimeout;
  };
};

// Stack requests and flush when exceed maxSize or after debounce time.
const aggregate = ({ debounceMsec = 1000, maxSize = 0 }) => {
  let requests = {};
  let debounceTimeout = null;

  return ({ request: { channelName, callback }, aggregateCallback }) => {
    clearTimeout(debounceTimeout);
    requests[channelName] = callback;

    if (0 < maxSize && maxSize <= Object.keys(requests).length) {
      const flushedRequests = {};
      const digestKeys = Object.keys(requests).slice(0, maxSize);
      digestKeys.forEach(channelName => {
        flushedRequests[channelName] = requests[channelName];
        delete requests[channelName];
      });
      aggregateCallback(flushedRequests);
    }

    if (0 === Object.keys(requests).length) {
      return;
    }

    debounceTimeout = setTimeout(() => {
      const flushedRequests = requests;
      requests = {};
      return aggregateCallback(flushedRequests);
    }, debounceMsec);
    return debounceTimeout;
  };
};

// Need to declare here since pusher keep re-construct BufferedAuthorizer.
let backoffHandler = null;
let criticalAggregateHandler = null;
let nonCriticalAggregateHandler = null;
export const resetRateLimitHandlers = () => {
  backoffHandler = null;
  criticalAggregateHandler = null;
  nonCriticalAggregateHandler = null;
};

class BufferedAuthorizer {
  constructor({
    socketId,
    authEndpoint,
    authDelay,
    authHeaders,
    authBatchMaxCount,
    onAuth,
  }) {
    this.socketId = socketId;
    this.authEndpoint = authEndpoint;
    this.authHeaders = authHeaders;
    this.authBatchMaxCount = authBatchMaxCount;
    this.onAuth = onAuth;
    this.authDelay = authDelay;
  }

  handleCriticalRequest = ({ channelName, callback }) => {
    if (!criticalAggregateHandler) {
      criticalAggregateHandler = aggregate({
        debounceMsec: 500,
        maxSize: this.authBatchMaxCount,
      });
    }

    return criticalAggregateHandler({
      request: { channelName, callback },
      aggregateCallback: requests => {
        // critical requests will keep re-try, so need backoff.
        if (!backoffHandler) {
          backoffHandler = backoff({ backoffUnitMsec: 3000 });
        }
        return backoffHandler({
          backoffCallback: () => this.executeRequests(requests),
        });
      },
    });
  };

  handleNonCriticalRequest = ({ channelName, callback }) => {
    // non-critical requests won't re-try, so just need max size.
    if (!nonCriticalAggregateHandler) {
      nonCriticalAggregateHandler = aggregate({
        debounceMsec: this.authDelay,
        maxSize: this.authBatchMaxCount,
      });
    }

    return nonCriticalAggregateHandler({
      request: { channelName, callback },
      aggregateCallback: this.executeRequests,
    });
  };

  add = (channelName, callback) => {
    if (getIsCriticalChannel({ channelName })) {
      this.handleCriticalRequest({ channelName, callback });
    } else {
      this.handleNonCriticalRequest({ channelName, callback });
    }
  };

  getHeaders = () => {
    const headers = new Headers();
    for (let headerName in this.authHeaders.headers) {
      headers.set(headerName, this.authHeaders.headers[headerName]);
    }
    return headers;
  };

  getFetchOptions = headers => ({ method: 'GET', headers });

  getFetchUrl = requests => {
    const url = new URL(this.authEndpoint);
    url.searchParams.append('socket_id', this.socketId);
    if (authorizerLanguage) {
      url.searchParams.set('lang', authorizerLanguage);
    }
    Object.keys(requests)
      .sort()
      .forEach(channel => {
        url.searchParams.append('channels', channel);
      });
    return url.href;
  };

  handleChannelSubscriptionFailed = ({ request, channelName }) => {
    const socket = getSocket();
    // channel retry logic: is critical channel and channel is existed and channel.subscriptionCancelled is false
    const shouldRetryChannel =
      getIsCriticalChannel({ channelName }) ||
      getIsRetryChannel({ channelName });
    if (
      shouldRetryChannel &&
      socket?.channels?.channels?.[channelName] &&
      !socket.channels.channels[channelName].subscriptionCancelled
    ) {
      if (socket?.channels?.channels?.[channelName]?.subscriptionPending) {
        delete socket.channels.channels[channelName];
      }

      this.add(channelName, request);
    } else {
      request(true, { channel: channelName });
      // Remove stucking subscriptionPending object in socket to prevent re-try problem
      if (socket?.channels?.channels?.[channelName]) {
        delete socket.channels.channels[channelName];
      }
    }
  };

  executeRequests = async requests => {
    const headers = this.getHeaders();
    const fetchOptions = this.getFetchOptions(headers);
    const fetchUrl = this.getFetchUrl(requests);
    const requestStart = Date.now();
    let requestEnd = Date.now();
    try {
      const response = await fetch(fetchUrl, fetchOptions);
      requestEnd = Date.now();
      if (!response.ok) {
        let payload = '';
        try {
          payload = await response.json();
          // eslint-disable-next-line no-empty
        } catch (_) {}
        throw new Error(
          JSON.stringify({
            status: response.status,
            description: 'pusher batch response is not ok',
            payload,
          })
        );
      }
      const data = await response.json();
      const isAllSubscriptionSucceed =
        Object.keys(requests).length ===
        Object.keys(data).filter(key => data[key]?.auth).length;
      if (isAllSubscriptionSucceed) {
        backoffHandler = null;
      }
      for (let channelName in requests) {
        const subscribeData = { ...data[channelName], socketId: this.socketId };
        if (subscribeData?.reason) {
          pusherLog({
            level: Severity.Error,
            messages: [
              `${channelName} authenticate error`,
              subscribeData.reason,
            ],
            data: {
              subscribeData,
              channelName,
              apiLatency: `${requestEnd - requestStart}ms`,
            },
          });
        }
        if (subscribeData?.auth) {
          if (getIsRetryChannel({ channelName })) {
            removeRetryChannel({ channelName });
          }
          addSecretKey({
            channelName,
            secretKey: subscribeData.shared_secret,
          });
          this.onAuth({
            channel: channelName,
            data: subscribeData,
          });
          requests[channelName](false, subscribeData);
          pusherLog({
            messages: [`${channelName} authenticated`],
            data: {
              subscribeData,
              channelName,
              channelData: JSON.parse(subscribeData.channel_data || '{}'),
              apiLatency: `${requestEnd - requestStart}ms`,
            },
          });
        } else {
          this.handleChannelSubscriptionFailed({
            request: requests[channelName],
            channelName,
          });
          pusherLog({
            level: Severity.Error,
            messages: [`${channelName} is missing in batch authenticate`],
            data: {
              subscribeData,
              channelName,
              apiLatency: `${requestEnd - requestStart}ms`,
            },
          });
        }
      }
    } catch (error) {
      const channelNames = Object.keys(requests);
      channelNames.forEach(channelName => {
        this.handleChannelSubscriptionFailed({
          request: requests[channelName],
          channelName,
        });
      });
      const apiLatency = `${requestEnd - requestStart}ms`;
      pusherLog({
        level: Severity.Error,
        messages: ['batch authenticate error'],
        data: {
          error,
          requests,
          channelNames,
          apiLatency: `${requestEnd - requestStart}ms`,
        },
      });

      let errorObject = null;
      try {
        errorObject = JSON.parse(error.message);
        if (429 === errorObject.status) {
          return;
        } else if (
          401 === errorObject.status &&
          'Signature has expired' === errorObject.payload?.message
        ) {
          sendMessageToApp({
            type: REFRESH_ACCESS_TOKEN,
          });
          // no need to log to sentry for token expire errors
          return;
        }
        // eslint-disable-next-line no-empty
      } catch (_) {}

      const { withScope, captureMessage, captureException } = await getSentry();
      if (withScope && captureMessage && captureException) {
        withScope(scope => {
          scope.setContext('request', { channelNames });
          let response = { apiLatency };
          if (errorObject) {
            response = {
              ...response,
              status: errorObject.status,
              payload: errorObject.payload,
            };
          }
          scope.setContext('response', response);
          scope.setFingerprint(['pusher', 'batch-authenticate']);
          if (errorObject) {
            captureMessage(errorObject.description);
          } else {
            captureException(error);
          }
        });
      }
    }
  };
}
