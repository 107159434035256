// pusherKeychain.js
'use strict';
import nacl from 'tweetnacl';

const hasTranscoders =
  typeof self !== 'undefined' && self.TextEncoder && self.TextDecoder;
const encoder = hasTranscoders ? new self.TextEncoder() : undefined;
const decoder = hasTranscoders ? new self.TextDecoder('utf8') : undefined;
const sharedSecretKeys = {};

const convertBase64toUint8Array = ({ base64 }) => {
  return Uint8Array.from(atob(base64), c => c.charCodeAt(0));
};

/**
 * Save shared key to keychain
 * @kind resource
 * @param {string} {secretKey} - Base64 string of secret key
 * @param {string} {channelName} - channel name
 * @return {string} decrypted message.
 */
export const addSecretKey = ({ channelName, secretKey }) => {
  if (!channelName || !secretKey || !encoder) {
    return;
  }

  try {
    sharedSecretKeys[channelName] = convertBase64toUint8Array({
      base64: secretKey,
    });
    // eslint-disable-next-line no-empty
  } catch (_) {}

  return;
};

/**
 * Decrypt pusher payload
 * @kind resource
 * @param {string} {cipherText} - Base64 string of pusher event payload
 * @param {string} {nonce} - Base64 string of pusher event nonce
 * @param {string} {channelName} - channel name
 * @return {string} decrypted message.
 */
export const decrypt = ({ cipherText, nonce, channelName }) => {
  if (!cipherText || !nonce || !channelName) {
    return null;
  }

  const key = sharedSecretKeys[channelName];
  if (!key) {
    return null;
  }

  let message = null;

  try {
    const box = convertBase64toUint8Array({ base64: cipherText });
    const n = convertBase64toUint8Array({ base64: nonce });
    const decrypted = nacl.secretbox.open(box, n, key);
    message = decoder.decode(decrypted);
    // eslint-disable-next-line no-empty
  } catch (_) {}

  return message;
};
