// SWMessageLocalTypes.js
// Local defined message types for SW to App communitcation
'use strict';

export const ON_AUTHENTICATED = 'ON_AUTHENTICATED';
export const CHANNEL_SUBSCRIPTION_UPDATED = 'CHANNEL_SUBSCRIPTION_UPDATED';
export const SET_PUSHER_CONNECTION_STATUS = 'SET_PUSHER_CONNECTION_STATUS';
export const SERVICE_WORKER_PONG = 'SERVICE_WORKER_PONG';
export const LOGOUT_FROM_SW = 'LOGOUT_FROM_SW';
export const PUSHER_EVENT_GOT = 'PUSHER_EVENT_GOT';
export const REFRESH_ACCESS_TOKEN = 'REFRESH_ACCESS_TOKEN';
export const RESTORE_REDUX = 'RESTORE_REDUX';
export const RESET_FCM_TOKEN = 'RESET_FCM_TOKEN';
export const UPDATE_PUSHER_DISTINCT_ID = 'UPDATE_PUSHER_DISTINCT_ID';
