// pusherController.js
'use strict';
import { LRUCache } from 'lru-cache';

import Delay from '../resource/Delay.js';
import DelayInstanceMap from '../resource/DelayInstanceMap.js';
import { getSentry, getSentry2Scope } from '../resource/sentry.js';
import {
  ON_AUTHENTICATED,
  CHANNEL_SUBSCRIPTION_UPDATED,
  SET_PUSHER_CONNECTION_STATUS,
  SERVICE_WORKER_PONG,
  LOGOUT_FROM_SW,
  PUSHER_EVENT_GOT,
  RESTORE_REDUX,
} from './SWMessageTypes.js';

import {
  SUBSCRIPTION_SUCCEEDED,
  SUBSCRIPTION_ERROR,
  EVENTS,
  STREAM_ONLINE,
  STREAM_OFFLINE,
  STREAM_VIEWERS_UPDATED,
  CONFIGURATION_UPDATED,
  GOAL_ADDED,
  GOAL_ENDED,
  GOAL_STARTED,
  COUNTER_ADDED,
  GOAL_PROGRESS_UPDATED,
  DEVICE_ONLINE,
  DEVICE_OFFLINE,
  GIFT_SENT,
  CHAT_USER_ENTERED,
  CHAT_MESSAGE_SENT,
  SIC_BO_CREATED,
  SIC_BO_ENDED,
} from './PusherEvents.js';

import {
  CONFIG_PRIORITY_PRESENCE_USER,
  CONFIG_PRIORITY_PRESENCE_CLIENT,
} from '../resource/configPriority.js';

import Pusher from './pusher.js';
import { decrypt } from './pusherKeychain.js';

import { getIsInServiceWorker } from '../resource/getJsEnvironment.js';
import { pusher as pusherDebug } from '../resource/debug.js';
import objectifyArrayById from '../resource/objectifyArrayById.js';
import batchPusherEvents from '../resource/batchPusherEvents.js';

const pusherLog = pusherDebug.extend('log:pusherController');
const pusherErrorLog = pusherDebug.extend('error:pusherController');

const isOnServiceWorker = getIsInServiceWorker();

let reaperTimer;
const pendingChannels = new Set();
const resubscribedPendingChannels = new LRUCache({ max: 32 });

const delayInstanceMap = new DelayInstanceMap();

/**
 * Send message from Service Worker to App
 * If service worker is not activated, tasks will fallback onto window context automatically
 * @param {object} event
 */
export const sendMessageToApp = async event => {
  if (!isOnServiceWorker) {
    const { sendMessageToApp_fallback } = await import('./helpers.js');
    return sendMessageToApp_fallback(event);
  }

  const clients = await self.clients.matchAll();
  clients.forEach(client => {
    client.postMessage(event);
  });
};

/**
 * Send message from Service Worker to App
 * If service worker is not activated, tasks will fallback onto window context automatically
 * @param {object} event
 * @param {object} tabId - specific browser tab id or other client id
 */
export const logoutOtherTabs = async (event, tabId) => {
  if (!isOnServiceWorker) return;
  const clients = await self.clients.matchAll();
  clients
    .filter(client => client.id !== tabId)
    .forEach(client => {
      client.postMessage(event);
    });
};

const decryptPusherPayload = ({ channelName, payload }) => {
  if (!payload?.nonce) {
    return payload;
  }

  try {
    const decryptedPayload = JSON.parse(
      decrypt({
        nonce: payload.nonce,
        cipherText: payload.ciphertext,
        channelName,
      })
    );

    return decryptedPayload;
  } catch (error) {
    pusherErrorLog(error);
    getSentry().then(({ withScope, captureException }) => {
      if (withScope && captureException) {
        withScope(scope => {
          scope.setExtra('channelName', channelName);
          scope.setExtra('payload', payload);
          captureException(error);
        });
      }
    });
  }
};

/**
 * @param {import('pusher-js').Channel} channel
 * @param {object|function} override metadata to be sent with
 */
export const bindEncryptedEvents = (channel, override = {}) => {
  channel.unbind(EVENTS); // Prevent rebinding
  channel.bind(EVENTS, _events => {
    const events = decryptPusherPayload({
      channelName: channel.name,
      payload: _events,
    });
    pusherLog(channel.name, events);

    const batchedEvents = batchPusherEvents({
      types: [
        CHAT_USER_ENTERED,
        CHAT_MESSAGE_SENT,
        GIFT_SENT,
        SIC_BO_CREATED,
        SIC_BO_ENDED,
      ],
      events: events,
    });
    batchedEvents?.forEach(({ event, data = {} } = {}) => {
      sendMessageToApp({
        type: PUSHER_EVENT_GOT,
        payload: {
          channelName: channel.name,
          eventType: event,
          payload: data,
        },
      });
      return sendMessageToApp({
        type: event,
        payload: {
          ...data,
          ...(typeof override === 'function' ? override(event) : override),
        },
      });
    });
  });
  if (channel?.callbacks?._callbacks?.[`_${EVENTS}`]) {
    // Prevent unbind race condition.
    channel.callbacks._callbacks[`_${EVENTS}`] = [
      channel.callbacks._callbacks[`_${EVENTS}`][0],
    ];
  }
};

/**
 * @param {import('pusher-js').Channel} channel
 */
export const bindSubscriptionEvents = channel => {
  channel.unbind(SUBSCRIPTION_ERROR);
  channel.bind(SUBSCRIPTION_ERROR, () => {
    unsubscribeChannel(channel.name);
  });
  channel.unbind(SUBSCRIPTION_SUCCEEDED);
  channel.bind(SUBSCRIPTION_SUCCEEDED, () => {
    sendMessageToApp({
      type: CHANNEL_SUBSCRIPTION_UPDATED,
      payload: {
        [channel.name]: {
          subscribed: channel.subscribed,
          subscriptionCancelled: channel.subscriptionCancelled,
          subscriptionPending: channel.subscriptionPending,
        },
      },
    });
  });
};

/**
 * Unsubscribe then subscribe the channel
 * When the channel subscription is stuck on the pending status,
 * unsubscribe and then subscribe does not relieve the situation
 * After try and error, we need to do the following steps
 * unsubscribe -> disconnect -> delete channel -> subscribe
 * Then the channel subscription backs normal
 * @param {String} channelName
 */
const subscribeChannelSafely = async ({ channelName, shouldRetry }) => {
  const socket = Pusher.getSocket();
  const channel = socket?.channels?.channels?.[channelName];

  if (channel) {
    channel?.unsubscribe();
    channel?.disconnect();
    delete socket.channels.channels[channelName];
  }

  return Pusher.subscribe({ channelName, shouldRetry });
};

const unsubscribeChannel = channelName => {
  Pusher.unsubscribe(channelName);
  sendMessageToApp({
    type: CHANNEL_SUBSCRIPTION_UPDATED,
    payload: {
      [channelName]: undefined,
    },
  });
};

/**
 * subscribe presence usesr channel
 * @param {string} {event.payload.userId} - user id.
 */
export const subscribeToPresenceUserChannel = async (event = {}) => {
  const { userId } = event.payload || {};
  if (!userId) {
    return;
  }
  const channel = await subscribeChannelSafely({
    channelName: `presence-enc-user@${userId}`,
  });
  bindSubscriptionEvents(channel);
  bindEncryptedEvents(channel, event =>
    event === CONFIGURATION_UPDATED
      ? { priority: CONFIG_PRIORITY_PRESENCE_USER }
      : {}
  );
};

/**
 * subscribe presence client channel
 * @param {string} {event.payload.clientId} - client id.
 */
export const subscribeToPresenceClientChannel = async (event = {}) => {
  const { clientId } = event.payload || {};
  if (!clientId) {
    return;
  }
  const channel = await subscribeChannelSafely({
    channelName: `presence-enc-client@${clientId}`,
  });
  bindSubscriptionEvents(channel);
  bindEncryptedEvents(channel, event =>
    event === CONFIGURATION_UPDATED
      ? { priority: CONFIG_PRIORITY_PRESENCE_CLIENT }
      : {}
  );
};

const pendingChannelReaper = ({ reaperInterval = 2000 } = {}) => {
  if (reaperTimer) clearInterval(reaperTimer);
  reaperTimer = setInterval(() => {
    const socket = Pusher.getSocket();

    if (socket) {
      socket
        .allChannels()
        .filter(channel => channel.subscriptionPending)
        .forEach(channel => {
          const channelName = channel.name;
          const isSubscriptionPending = channel.subscriptionPending;

          if (pendingChannels.has(channelName)) {
            if (isSubscriptionPending) {
              pusherLog('pending channel', channelName, {
                subscribed: channel.subscribed,
                subscriptionCancelled: channel.subscriptionCancelled,
                subscriptionPending: channel.subscriptionPending,
              });
              const isSubscriptionCancelled = channel.subscriptionCancelled;

              // remove zombie channels
              channel.unsubscribe();
              channel.disconnect();
              delete socket.channels.channels[channelName];

              // only retry once, otherwise we may potentially attack backend
              if (
                !isSubscriptionCancelled &&
                !resubscribedPendingChannels.has(channelName)
              ) {
                Pusher.subscribe({ channelName });
                resubscribedPendingChannels.set(channelName, 1);
                pusherLog('resubscribe', channelName, {
                  pendingChannelsSize: pendingChannels.size,
                  resubscribedPendingChannelsSize:
                    resubscribedPendingChannels.size,
                });
              }
            } else {
              pendingChannels.delete(channelName);

              if (resubscribedPendingChannels.has(channelName))
                resubscribedPendingChannels.delete(channelName);
            }
          } else {
            pendingChannels.add(channelName);
          }
        });

      // clear the channels that non-exist or subscribe success
      const remainingAllChannels = socket.channels.channels;
      pendingChannels.forEach(channelName => {
        if (
          !remainingAllChannels[channelName] ||
          remainingAllChannels[channelName]?.subscribed
        )
          pendingChannels.delete(channelName);
      });

      resubscribedPendingChannels.forEach((_, channelName) => {
        if (
          !remainingAllChannels[channelName] ||
          remainingAllChannels[channelName]?.subscribed
        )
          resubscribedPendingChannels.delete(channelName);
      });

      pusherLog(
        'pending and retried channel status',
        {
          pendingChannelsSize: pendingChannels.size,
          resubscribedPendingChannelsSize: resubscribedPendingChannels.size,
        },
        {
          pendingChannels,
          resubscribedPendingChannels,
        }
      );
    }
  }, reaperInterval);
};

/**
 * connect pusher and subscribe to channels
 */
export const init = event => {
  const { pusherConfig = {} } = event.payload;
  const clientId = pusherConfig.headers?.['X-Client-ID'];
  getSentry().then(({ setTag }) => {
    if (setTag) {
      setTag('client_id', clientId);
    }
  });

  Pusher.connect({
    ...pusherConfig,
    onAuth: ({ channel, data }) => {
      sendMessageToApp({
        type: ON_AUTHENTICATED,
        payload: { channel, ...data },
      });
    },
    onEvent: event => {
      const { name, state } = event;
      if ('state_change' === name) {
        sendMessageToApp({
          type: SET_PUSHER_CONNECTION_STATUS,
          payload: { state, current: state },
        });
      }
    },
  });
  Pusher.connectEventListener(({ current, previous }) =>
    sendMessageToApp({
      type: SET_PUSHER_CONNECTION_STATUS,
      payload: {
        state: current,
        current,
        previous,
      },
    })
  );
  subscribeToPresenceClientChannel({
    payload: {
      clientId,
    },
  });

  pendingChannelReaper({ reaperInterval: pusherConfig.reaperInterval });
};

export const disconnect = () => {
  Pusher.disconnect();
};

/**
 * Get the A/B test token
 * @param {Object} event
 * @param {{ userId: string }} event.payload
 */
export const getAbTestToken = ({ payload }) => {
  const { userId, clientId } = payload;
  if (clientId)
    return subscribeToPresenceClientChannel({
      payload: {
        clientId,
      },
    });

  subscribeToPresenceUserChannel({
    payload: {
      userId,
    },
  });
};

/**
 * login
 * @param {Object} event - the event object from the client
 */
export const login = async event => {
  const Sentry = await getSentry();
  const { userId, headers } = event.payload;
  const sentry2Scope = getSentry2Scope();
  if (Sentry) {
    const scope = Sentry.getCurrentScope();
    scope.setUser({ id: userId });
  }

  if (sentry2Scope) sentry2Scope.setUser({ id: userId });

  Pusher.setAuthHeaders({ headers });
  subscribeToPresenceUserChannel({
    payload: {
      userId,
    },
  });

  const meStatus = {
    payload: {
      userId,
      shouldRetry: false,
    },
  };
  userOnlineStatus(meStatus);

  const clientId = headers?.['X-Client-ID'];
  if (clientId) {
    subscribeToPresenceClientChannel({
      payload: {
        clientId,
      },
    });
  }
};

/**
 * setAuthHeaders
 * @param {Object} headers - the event object from the client
 */
export const setAuthHeaders = event => {
  const { headers } = event.payload;
  Pusher.setAuthHeaders({ headers });

  const clientId = headers['X-Client-ID'];
  subscribeToPresenceClientChannel({
    payload: {
      clientId,
    },
  });
};

export const setAuthorizerLanguage = async event => {
  const { language } = event.payload;
  await Pusher.setAuthorizerLanguage({ language });
};

/**
 * Unsubscribe channels which name starts with `presence-`, except `presence-enc-client@`
 */
export const logout = (event, tabId) => {
  let clientId = undefined;
  Pusher.getAllChannels()?.forEach(({ name }) => {
    if (/^presence-/.test(name) && !/^presence-enc-client@/.test(name)) {
      unsubscribeChannel(name);
    }
    if (/^presence-enc-client@/.test(name)) {
      clientId = /^presence-enc-client@([a-z\d-]+)$/i.exec(name)?.[1];
    }
  });
  logoutOtherTabs({ type: LOGOUT_FROM_SW }, tabId);

  // Update ab token from presence client after logout
  subscribeToPresenceClientChannel({
    payload: {
      clientId,
    },
  });
};

/**
 * check user online status
 * @param {function} {event.dispatch}
 * @param {object} {event.payload}
 * @param {string} {event.payload.userId}
 */
export const userOnlineStatus = async event => {
  const { userId, shouldRetry } = event.payload;
  const channel = await subscribeChannelSafely({
    channelName: `private-enc-user@${userId}`,
    shouldRetry,
  });
  bindSubscriptionEvents(channel);
  bindEncryptedEvents(channel, event =>
    [
      COUNTER_ADDED,
      STREAM_ONLINE,
      STREAM_OFFLINE,
      STREAM_VIEWERS_UPDATED,
      GOAL_ADDED,
      GOAL_ENDED,
      GOAL_STARTED,
      GOAL_PROGRESS_UPDATED,
      DEVICE_ONLINE,
      DEVICE_OFFLINE,
    ].includes(event)
      ? { userId, streamId: userId }
      : { userId }
  );
};

export const subscribePresenceMessageChannel = async event => {
  const { messageId, uploadJobId } = event.payload;
  const channel = await subscribeChannelSafely({
    channelName: `presence-enc-message@${messageId}`,
  });
  bindSubscriptionEvents(channel);
  bindEncryptedEvents(channel, { messageId, uploadJobId });
};

export const unsubscribePresenceMessageChannel = event => {
  const { messageId } = event.payload;
  unsubscribeChannel(`presence-enc-message@${messageId}`);
};

/**
 * Ping Service Worker
 */
export const pingServiceWorker = event => {
  const { timestamp } = event.payload;

  const connection = Pusher.getConnectState();
  sendMessageToApp({
    type: SET_PUSHER_CONNECTION_STATUS,
    payload: {
      state: connection,
    },
  });

  if (
    !connection ||
    ['unavailable', 'failed', 'disconnected'].includes(connection)
  ) {
    sendMessageToApp({
      type: SERVICE_WORKER_PONG,
      payload: {
        status: {
          error: 'pusher-connection-failed',
          connection,
        },
      },
    });
  } else {
    sendMessageToApp({
      type: SERVICE_WORKER_PONG,
      payload: {
        status: {
          pong: timestamp,
        },
      },
    });
  }
};

/**
 * Get pusher connection Status
 */
export const getPusherConnectionStatus = () => {
  const name = Pusher.getSocket()?.connection?.connection?.transport.name;
  sendMessageToApp({
    type: SET_PUSHER_CONNECTION_STATUS,
    payload: {
      transport: {
        name,
      },
    },
  });
};

/**
 * Get Stream Channel Status
 * @param {object} {event.payload}
 * @param {string} {event.payload.userId}
 * @param {string} {event.payload.streamId}
 * @param {string} {event.payload.meId}
 */
export const getStreamChannelStatus = event => {
  const { userId, streamId, meId } = event.payload;
  const privateUserChannelName = `private-enc-user@${userId}`;
  const privateUserChannel =
    Pusher.getSocket()?.channels.channels[privateUserChannelName];

  const privateStreamChannelName = `private-enc-stream@${streamId}`;
  const privateStreamChannel =
    Pusher.getSocket()?.channels.channels[privateStreamChannelName];

  const presenceStreamViewerPriviewChannelName = `presence-enc-stream-viewer@${streamId}.preview.${meId}`;
  const presenceStreamViewerPriviewChannel =
    Pusher.getSocket()?.channels.channels[
      presenceStreamViewerPriviewChannelName
    ];

  const presenceStreamViewerSdChannelName = `presence-enc-stream-viewer@${streamId}.sd.${meId}`;
  const presenceStreamViewerSdChannel =
    Pusher.getSocket()?.channels.channels[presenceStreamViewerSdChannelName];

  const presenceUserChannelName = `presence-enc-user@${meId}`;
  const presenceUserChannel =
    Pusher.getSocket()?.channels.channels[presenceUserChannelName];

  const presenceStreamExclusiveChannelName = `presence-enc-stream-exclusive@${streamId}`;
  const presenceStreamExclusiveChannel =
    Pusher.getSocket()?.channels.channels[presenceStreamExclusiveChannelName];

  sendMessageToApp({
    type: CHANNEL_SUBSCRIPTION_UPDATED,
    payload: {
      [privateUserChannelName]: {
        subscribed: privateUserChannel?.subscribed,
        subscriptionCancelled: privateUserChannel?.subscriptionCancelled,
        subscriptionPending: privateUserChannel?.subscriptionPending,
      },
      [privateStreamChannelName]: {
        subscribed: privateStreamChannel?.subscribed,
        subscriptionCancelled: privateStreamChannel?.subscriptionCancelled,
        subscriptionPending: privateStreamChannel?.subscriptionPending,
      },
      [presenceStreamViewerPriviewChannelName]: {
        subscribed: presenceStreamViewerPriviewChannel?.subscribed,
        subscriptionCancelled:
          presenceStreamViewerPriviewChannel?.subscriptionCancelled,
        subscriptionPending:
          presenceStreamViewerPriviewChannel?.subscriptionPending,
      },
      [presenceStreamViewerSdChannelName]: {
        subscribed: presenceStreamViewerSdChannel?.subscribed,
        subscriptionCancelled:
          presenceStreamViewerSdChannel?.subscriptionCancelled,
        subscriptionPending: presenceStreamViewerSdChannel?.subscriptionPending,
      },
      [presenceUserChannelName]: {
        subscribed: presenceUserChannel?.subscribed,
        subscriptionCancelled: presenceUserChannel?.subscriptionCancelled,
        subscriptionPending: presenceUserChannel?.subscriptionPending,
      },
      [presenceStreamExclusiveChannelName]: {
        subscribed: presenceStreamExclusiveChannel?.subscribed,
        subscriptionCancelled:
          presenceStreamExclusiveChannel?.subscriptionCancelled,
        subscriptionPending:
          presenceStreamExclusiveChannel?.subscriptionPending,
      },
    },
  });
};

/**
 * subscribe stream viewer channel
 * @param {string} {event.payload.streamId} - stream id.
 * @param {string} {event.payload.userId} - viewer user id.
 * @param {string} {event.payload.preset} - stream preset.
 */
export const subscribeStreamViewerChannel = async event => {
  const { streamId, preset, userId } = event.payload;
  const channelName = `presence-enc-stream-viewer@${streamId}.${preset}.${userId}`;
  delayInstanceMap.delete(channelName);
  const channel = await subscribeChannelSafely({
    channelName,
  });
  bindSubscriptionEvents(channel);
  bindEncryptedEvents(channel, { preset, streamId });
};

/**
 * unsubscribe stream viewer channel
 * @param {string} {event.payload.streamId} - stream id.
 * @param {string} {event.payload.userId} - viewer user id.
 * @param {string} {event.payload.preset} - stream preset.
 */
export const unsubscribeStreamViewerChannel = async event => {
  const { streamId, preset, userId } = event.payload;
  const channelName = `presence-enc-stream-viewer@${streamId}.${preset}.${userId}`;
  const delay = new Delay(5000); // TODO: remote config
  delayInstanceMap.add(channelName, delay);
  await delay.promise();
  if (delayInstanceMap.getInstance(channelName)) {
    unsubscribeChannel(channelName);
  }
};

/**
 * subscribe private stream channel
 * @param {string} {event.payload.streamId} - stream id.
 */
export const subscribeStreamChannel = async event => {
  const { streamId } = event.payload;
  const channel = await subscribeChannelSafely({
    channelName: `private-enc-stream@${streamId}`,
  });
  bindSubscriptionEvents(channel);
  bindEncryptedEvents(channel, {
    streamId,
  });
};

/**
 * unsubscribe private stream channel
 * @param {string} {event.payload.streamId} - stream id.
 */
export const unsubscribeStreamChannel = event => {
  const { streamId } = event.payload;
  unsubscribeChannel(`private-enc-stream@${streamId}`);
};

/**
 * subscribe private goal channel
 * @param {string} {event.payload.goalId} - goal id.
 */
export const subscribeGoalChannel = async event => {
  const { goalId } = event.payload;
  const channel = await subscribeChannelSafely({
    channelName: `private-enc-goal@${goalId}`,
  });
  bindSubscriptionEvents(channel);
  bindEncryptedEvents(channel, { goalId });
};

/**
 * unsubscribe private goal channel
 * @param {string} {event.payload.goalId} - goal id.
 */
export const unsubscribeGoalChannel = event => {
  const { goalId } = event.payload;
  unsubscribeChannel(`private-enc-goal@${goalId}`);
};

/**
 * subscribe presence goal channel
 * @param {string} {event.payload.goalId} - goal id.
 * @param {string} {event.payload.userId} - user id.
 */
export const subscribePresenceGoalChannel = async event => {
  const { goalId, userId } = event.payload;
  const channel = await subscribeChannelSafely({
    channelName: `presence-enc-goal@${goalId}.${userId}`,
  });
  bindSubscriptionEvents(channel);
  bindEncryptedEvents(channel, { goalId });
};

/**
 * unsubscribe presence goal channel
 * @param {string} {event.payload.goalId} - goal id.
 * @param {string} {event.payload.userId} - user id.
 */
export const unsubscribePresenceGoalChannel = event => {
  const { goalId, userId } = event.payload;
  unsubscribeChannel(`presence-enc-goal@${goalId}.${userId}`);
};

/**
 * subscribe campaign channel
 * @param {string} {event.payload.campaignName} - campaign name.
 */
export const subscribeCampaignChannel = async event => {
  const { campaignName } = event.payload;
  const channel = await subscribeChannelSafely({
    channelName: `private-enc-campaign@${campaignName}`,
  });
  bindSubscriptionEvents(channel);
  bindEncryptedEvents(channel, { campaignName });
};

/**
 * unsubscribe campaign channel
 * @param {string} {event.payload.campaignName} - campaign name.
 */
export const unsubscribeCampaignChannel = event => {
  const { campaignName } = event.payload;
  unsubscribeChannel(`private-enc-campaign@${campaignName}`);
};

/**
 * subscribe feed channel
 * @param {string} {event.payload.feedName} - feed name.
 */
export const subscribeFeedChannel = async event => {
  const { feedName } = event.payload;
  const channel = await subscribeChannelSafely({
    channelName: `private-enc-feed@${feedName}`,
  });
  bindSubscriptionEvents(channel);
  bindEncryptedEvents(channel, { feedName });
};

/**
 * unsubscribe feed channel
 * @param {string} {event.payload.feedName} - feed name.
 */
export const unsubscribeFeedChannel = event => {
  const { feedName } = event.payload;
  unsubscribeChannel(`private-enc-feed@${feedName}`);
};

/**
 * Subscribe presence asset channel
 * @param {string} {event.payload.assetId} - asset id.
 * @param {boolean} {event.payload.shouldRetry} - should retry subscribe channel.
 */
export const subscribeAssetChannel = async event => {
  const { assetId, shouldRetry = true } = event.payload;
  const channel = await subscribeChannelSafely({
    channelName: `presence-enc-asset@${assetId}`,
    shouldRetry,
  });
  bindSubscriptionEvents(channel);
  bindEncryptedEvents(channel, { assetId });
};

/**
 * Unsubscribe presence asset channel
 * @param {string} {event.payload.assetId} - asset id.
 */
export const unsubscribeAssetChannel = event => {
  const { assetId } = event.payload;
  unsubscribeChannel(`presence-enc-asset@${assetId}`);
};

/**
 * Subscribe presence order channel
 * @param {string} {event.payload.orderId} - order id.
 */
export const subscribeOrderChannel = async event => {
  const { orderId } = event.payload;
  const channel = await subscribeChannelSafely({
    channelName: `presence-enc-order@${orderId}`,
  });
  bindSubscriptionEvents(channel);
  bindEncryptedEvents(channel, { orderId });
};

/**
 * Subscribe presence stream exclusive channel
 * @param {string} {event.payload.streamId} - livestream id.
 */
export const subscribePresenceStreamExclusive = async event => {
  const { streamId } = event.payload;
  const channel = await subscribeChannelSafely({
    channelName: `presence-enc-stream-exclusive@${streamId}`,
  });
  bindSubscriptionEvents(channel);
  bindEncryptedEvents(channel, { streamId });
};

/**
 * Unsubscribe presence stream exclusive channel
 * @param {string} {event.payload.streamId} - livestream id.
 */
export const unsubscribePresenceStreamExclusive = event => {
  const { streamId } = event.payload;
  unsubscribeChannel(`presence-enc-stream-exclusive@${streamId}`);
};

/**
 * Send player status data to channel
 * @param {string} {event.payload.client} - client id.
 * @param {string} {event.payload.payload} - recorded stream data
 */
export const sendPlayerStatusToChannel = async event => {
  const { clientId, payload } = event.payload;
  const channelName = `presence-enc-client@${clientId}`;
  let channel = Pusher.getSocket()?.channels.channels[channelName];
  if (!channel) {
    await subscribeToPresenceClientChannel({
      payload: {
        clientId,
      },
    });
  }

  channel?.trigger('client-player-status', payload);
};

/**
 * Get Channel Status
 * @param {string} {event.payload.channelNames} - channel name list.
 */
export const getChannelStatus = event => {
  const { channelNames = [] } = event.payload;
  const result = objectifyArrayById({
    array: channelNames.map(channelName => {
      const channel =
        Pusher.getSocket()?.channels?.channels?.[channelName] || {};
      const { subscribed, subscriptionCancelled, subscriptionPending } =
        channel;
      return {
        subscribed,
        subscriptionCancelled,
        subscriptionPending,
        channelName,
      };
    }),
    keyAsId: 'channelName',
  });
  sendMessageToApp({
    type: CHANNEL_SUBSCRIPTION_UPDATED,
    payload: result,
  });
};

/**
 * Restore redux from indexedDB
 * @param {string} tabId - specific browser tab id or other client id
 */
export const restoreRedux = async tabId => {
  if (!isOnServiceWorker) return;
  const clients = await self.clients.matchAll();

  clients
    .filter(client => client.id !== tabId)
    .forEach(client => {
      client.postMessage({ type: RESTORE_REDUX });
    });
};
